<template>
  <v-card>
    <loader v-if="showLoader" />
    <v-card-text v-else>
      <span class="label-profile">{{
        "sign_up_placeholder_first_name" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.first_name"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="firstNameError | localize"
        @blur="$v.user.first_name.$touch()"
      >
      </v-text-field>
      <span class="label-profile">{{
        "sign_up_placeholder_last_name" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.last_name"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="lastNameError | localize"
        @blur="$v.user.last_name.$touch()"
      >
      </v-text-field>
      <span class="label-profile">{{
        "faq_placeholder_email" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.email"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="emailError | localize"
        @blur="$v.user.email.$touch()"
      >
      </v-text-field>
      <span class="label-profile">{{
        "sign_up_placeholder_birth_date" | localize
      }}</span>
      <v-menu
        ref="menu"
        v-model="menu"
        size="small"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pa-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="rounded-lg"
            color="#e62076"
            dense
            append-icon="mdi-calendar"
            @click:append="on.click"
            :value="
              user.dob
                ? new Date(
                    user.dob.substr(0, 4),
                    user.dob.substr(6, 1) - 1,
                    user.dob.substr(8, 2)
                  ).toLocaleDateString('en-gb', { timeZone: 'Europe/London',})
                : ''
            "
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker
          :max="
            new Date(new Date().setFullYear(new Date().getFullYear() - 16))
              .toISOString()
              .substr(0, 10)
          "
          :min="
            new Date(new Date().setFullYear(new Date().getFullYear() - 100))
              .toISOString()
              .substr(0, 10)
          "
          v-model="user.dob"
          no-title
          scrollable
          color="#e62076"
          :locale="lang == 'en' ? 'en' : 'es'"
          ref="picker"
          @change="$refs.menu.save(user.birthDate), (menu = false)"
        >
          <v-spacer></v-spacer>
          <v-btn text color="#e62076" @click="menu = false">
            {{ "button_cancel" | localize }}
          </v-btn>
          <v-btn text color="#e62076" @click="$refs.menu.save(user.dob)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <span class="label-profile">{{
        "sign_up_placeholder_gender" | localize
      }}</span>
      <v-select
        class="rounded-lg"
        dense
        :items="Object.values(items)"
        :item-value="'value'"
        :item-text="'title'"
        outlined
        v-model.trim="user.gender"
        placholder="Choose here.."
        color="#e62076"
      >
      </v-select>
      <span class="label-profile"
        >{{ "cart_details_placeholder_country" | localize }}
        <span class="req">*</span></span
      >
      <v-autocomplete
        dense
        outlined
        class="rounded-lg"
        :items="Object.values(country)"
        :item-value="'id'"
        :item-text="'name'"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="countryError | localize"
        v-model="user.country"
        @blur="$v.user.country.$touch()"
      />
      <span class="label-profile" v-if="isMexico">
        {{'cart_details_placeholder_state' | localize}}
        <span class="req">*</span>
      </span>
      <v-autocomplete
        v-if="isMexico"
        dense
        outlined
        class="rounded-lg"
        v-model="userCountry.state"
        :items="Object.values(states)"
        :item-value="'id'"
        :item-text="'name'"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="stateError | localize"
        @blur="$v.userCountry.state.$touch()"
      />
      <!-- <v-text-field
        class="mt-1 iconPass"
        dense
        outlined
        placeholder="Type here.."
        v-model.trim="userCountry.state"
        :error-messages="isMexico ? stateError : ''"
        v-else
      /> -->
      <span class="label-profile">
       {{'sign_up_city_placeholder' | localize}} <span class="req" v-if="isMexico">*</span></span
      >
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="userCountry.city"
        color="#e62076"
        placeholder="Type here.."
        :error-messages="isMexico ?  $options.filters.localize(cityError) : ''"
        @blur="$v.userCountry.city.$touch()"
      />
      <span class="label-profile" v-if="!localUser">{{
        "sign_up_placeholder_hotel" | localize
      }}</span>
      <v-autocomplete
        v-if="!localUser"
        dense
        outlined
        class="rounded-lg"
        :items="Object.values(hotel)"
        :item-value="'uid'"
        :item-text="'shop_name'"
        v-model="user.hotel"
        :no-data-text="'not_available_hotels' | localize"
        color="#e62076"
        placeholder="Type here.."
      />
    </v-card-text>
    <v-card-actions>
      <v-row align="center" justify="end" class="mb-2 mr-1">
        <v-btn text color="#e62076" class="mr-2" @click="$emit('close')">
          {{ "button_cancel" | localize }}
        </v-btn>
        <v-btn color="#e62076" dark @click="updateProfile">
          {{ "button_update" | localize }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ListsService from "../../../requests/Lists/ListsService";
import ProfileService from "../../../requests/Profile/ProfileService";
import WhereToStayService from "../../../requests/WhereToStay/WhereToStayService";
import Loader from "../../UI/Loader.vue";
export default {
  components: { Loader },
  mixins: [validationMixin],
  data: () => ({
    user: {
      dob: "",
      country: "",
      gender: "",
    },
    items: [],
    itemsEN: [
      { value: "Male", title: "Male" },
      { value: "Female", title: "Female" },
      { value: "Other", title: "Other" },
    ],
    itemsES: [
      { value: "Male", title: "Hombre" },
      { value: "Female", title: "Mujer" },
      { value: "Other", title: "Otro" },
    ],
    lang: "",
    neighbourhood: [],
    country: [],
    hotel: [],
    states: [],
    showLoader: true,
    localUser: true,
    menu: null,
    userCountry: {
      state: "",
      city: "",
    },
    isMexico: false,
  }),
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      country: {
        required,
      },
      // dob: {
      //   required,
      // },
      // gender: {
      //   required,
      // },
    },
    userCountry: {
      state: {
        required,
      },
      city: {
        required,
      },
    },
  },
  mounted() {
    this.lang = this.loggedUser
      ? this.loggedUser.locale
      : this.userLocale.locale;
    this.items = this.lang == "en" ? this.itemsEN : this.itemsES;
    this.getCountry();
    this.getHotels();
    this.getUserInfo();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    updateProfile() {
      this.$v.$touch();
      if (this.isMexico) {
        if (!this.$v.$invalid) {
          this.updateInfo();
        }
      } else {
        if (!this.$v.user.$invalid) {
          this.updateInfo();
        }
      }
    },
    async updateInfo() {
      let form = new FormData();
      form.append("first_name", this.user.first_name);
      form.append("last_name", this.user.last_name);
      form.append("hotel", this.user.hotel || "");
      form.append("email", this.user.email);
      // form.append("neighbourhood", this.user.neighbourhood);
      form.append("user_city", this.userCountry.city);
      form.append("state", this.userCountry.state);
      form.append("country", this.user.country);
      form.append("type", this.localUser ? "Local" : "Tourist");
      if (this.user.dob !== "") {
        form.append("dob", this.user.dob);
      }
      if (this.user.gender) {
        form.append("gender", this.user.gender);
      }
      form.append("lang", this.lang);
      await ProfileService.updateInfo(form).then((response) => {
        this.updateInfoLogged({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
        });
        this.$emit("close");
      });
      // if (response.status == "success") {
      // }
    },
    // async getNeighbourhood() {
    //   let response = await ListsService.getNeighbourhood();
    //   this.neighbourhood = response.result;
    // },
    async getCountry() {
      let response = await ListsService.getCountry();
      this.country = response.data;
    },
    async getStates() {
      let response = await ListsService.getState(this.user.country);
      this.states = response.data;
      this.localUser =
        this.states.filter(
          (state) => state.id == response.data.location.state.id
        )[0]?.name == "Jalisco"
          ? true
          : false;
    },
    async getHotels() {
      let response = await WhereToStayService.getBusinessFromCategory(
        "183,384,28"
      );
      this.hotel = response.result;
    },
    async getUserInfo() {
      let response = await ProfileService.getUserInfo();
      this.$set(this.user, "first_name", response.data.first_name);
      this.$set(this.user, "last_name", response.data.last_name);
      this.$set(this.user, "email", response.data.email);
      this.$set(this.user, "gender", response.data.gender);

      if (response.data.dob != "0000-00-00") {
        this.$set(this.user, "dob", response.data.dob);
      }
      this.$set(this.user, "hotel", response.data.hotel);
      this.$set(this.user, "country", response.data.location.country.id);
      this.$set(this.userCountry, "state", response.data.location.state.id);
      if (response.data.location.city !== "Unknown") {
        console.log(response.data.location.city);
        this.$set(this.userCountry, "city", response.data.location.city);
      }
      // this.user.neighbourhood = response.data.neighbourhood.toString();
      this.showLoader = false;
    },
  },
  watch: {
    "user.country": {
      handler() {
        let country = this.country.filter(
          (country) => country.id == this.user.country
        );
        if (this.user.country !== "" && this.user.country !== undefined) {
          if (country[0].have_states == "Yes") {
            this.getStates();
          } else if (this.userCountry.state !== null) {
            this.userCountry = {
              state: "",
              city: "",
            };
          }
        }
        if (
          country[0].name.includes("México") ||
          country[0].name.includes("Mexico")
        ) {
          this.showConfirmLocalModal = true;
          this.isMexico = true;
        } else {
          this.localUser = false;
          this.isMexico = false;
        }
      },
    },
    "userCountry.state": {
      handler() {
        let state = this.states.filter(
          (state) => state.id == this.userCountry.state
        )[0];
        if (state.name !== "Jalisco") {
          this.localUser = false;
        } else {
          this.localUser = true;
        }
      },
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({ userLocale: "user" }),
    ...mapGetters(["loggedUser"]),
    firstNameError() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) {
        return errors;
      }
      !this.$v.user.first_name.required &&
        errors.push("sign_up_validation_first_name_required");
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) {
        return errors;
      }
      !this.$v.user.last_name.required &&
        errors.push("sign_up_validation_last_name_required");
      return errors;
    },
    countryError() {
      const errors = [];
      if (!this.$v.user.country.$dirty) {
        return errors;
      }
      !this.$v.user.country.required &&
        errors.push("sign_up_validation_country_required");
      return errors;
    },
    stateError() {
      const errors = [];
      if (!this.$v.userCountry.state.$dirty) {
        return errors;
      }
      !this.$v.userCountry.state.required &&
        errors.push("sign_up_mexico_state_validation");
      return errors;
    },
    cityError() {
      const errors = [];
      if (!this.$v.userCountry.city.$dirty) {
        return errors;
      }
      !this.$v.userCountry.city.required &&
        errors.push("sign_up_mexico_city_validation");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.user.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    // dobError() {
    //   const errors = [];
    //   if (!this.$v.user.dob.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.user.dob.required && errors.push("Dob field is required");
    //   return errors;
    // },
    // genderError() {
    //   const errors = [];
    //   if (!this.$v.user.gender.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.user.gender.required &&
    //     errors.push("sign_up_validation_gender_required");
    //   return errors;
    // },
  },
};
</script>

<style>
.label-profile {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.req {
  color: red;
}
</style>
