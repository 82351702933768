<template>
  <v-main>
      <v-container>
          <component-title title="header_my_account"/>
          <user-profile-component />
      </v-container>
  </v-main>
</template>

<script>
import userProfileComponent from '../components/userProfile/userProfileComponent.vue';
import ComponentTitle from '../components/UI/ComponentTitle.vue';
export default {
    components: {
        userProfileComponent,
        ComponentTitle,
    }
}
</script>

<style>

</style>