<template>
  <div class="mr-5">
    <div class="header">
      <v-row class="px-15 pt-3" align="center" justify="center">
        <v-divider style="border: 1px solid #ffffff" />
        <img
          :src="require('@/assets/' + $options.filters.city('white_main_logo'))"
          class="pass-logo ml-5 mr-5"
          :class="{ 'pass-logo-mzt': mazatlan }"
        />
        <v-divider style="border: 1px solid #ffffff" />
      </v-row>
      <v-row
        justify="center"
        class="white--text"
        :class="{ 'mt-0': mazatlan, 'mt-4': !mazatlan }"
      >
        <div
          style="
            width: 90%;
            height: 60px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <span style="font-weight: 800; font-size: 21px">{{
            locale == "en" ? citypass.title_en : citypass.title_es
          }}</span>
        </div>
        <br />
      </v-row>
      <v-row justify="center" class="white--text mt-4">
        <span style="font-weight: 700; font-size: 14px">{{
          "city_passes_label_city_pass" | localize
        }}</span
        ><br />
      </v-row>
      <v-row
        justify="center"
        class="white--text"
        style="font-weight: 800; font-size: 32px; bottom: 0"
        v-if="citypass.term"
      >
        <h3>{{ citypass.term }} {{ "city_passes_label_days" | localize }}</h3>
        <br />
      </v-row>
    </div>
    <div class="citypass">
      <span>{{ "profile_label_order" | localize }} - {{ citypass.id }}</span
      ><br />
      <span
        >{{ "cart_label_total_cost" | localize }} - MX$&nbsp;{{
          citypass.totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }}</span
      ><br />
      <span
        >{{ "attractions_adult" | localize }} -
        {{ parseInt(citypass.quantity_adult) }}</span
      ><br />
      <span v-if="citypass.quantity_kid > 0"
        >{{ "cart_label_child" | localize }} -
        {{ parseInt(citypass.quantity_kid) }}</span
      ><br v-if="citypass.quantity_kid > 0" />
      <span>{{ "infants_label" | localize }}</span
      ><br />
      <span
        >{{ "profile_label_activation_date" | localize }} -
        <span>{{
          new Date(
            citypass.started_at
          ).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}</span>
        <!-- timeZone: "Europe/London", -->
        <!-- <span v-else>{{
          new Date(
            citypass.started_at.substr(0, 4),
            citypass.started_at.substr(6, 1) - 1,
            citypass.started_at.substr(8, 2)
          ).toLocaleDateString("es-mx", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}</span> --> </span
      ><br />
      <v-row justify="center" no-gutters
        ><v-btn
          width="221px"
          height="44px"
          style="
            text-transform: unset !important;
            font-weight: 700;
            font-size: 16px;
          "
          :style="
            citypass.quantity_kid > 0
              ? 'margin-top: 19px;'
              : 'margin-top: 39px;'
          "
          color="#6ABAA4"
          class="white--text"
          @click="
            $router.push(
              '/profile/detail/info/my_citypass/' +
                citypass.citypass_uid +
                '/' +
                citypass.order_uid +
                '/' +
                citypass.parent_uuid
            )
          "
        >
          {{ "profile_button_see_details" | localize }}
        </v-btn></v-row
      >
    </div>
  </div>
</template>

<script>
import cityMixin from "@/mixins/cityMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";

export default {
  mixins: [cityMixin, userSettingsMixin],
  props: {
    citypass: {
      require: true,
    },
  },
};
</script>

<style scoped>
.header {
  height: 208px;
  width: 320px;
  background-color: #e62076;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}
.citypass {
  border: 2px solid #e62076;
  width: 320px;
  height: 229px;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  padding: 20px;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 14px;
}
</style>