import store from '@/store';
import axios from 'axios';

const api = process.env.VUE_APP_API_CAMPUS

export default {
	async getProfile(token) {
		const response = await axios.get(`${api}/users/user`, {
			headers: {
				'X-API-KEY': `${token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async getUserInfo() {
		const response = await axios.get(`${api}/users/user_info_full?user_id=${store.getters.loggedUser.id}`, {
			headers: {
				'X-API-KEY': `${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async updateInfo(form) {
		const response = await axios.post(`${api}/users/update_profile`, form, {
			headers: {
				'X-API-KEY': `${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async updatePassword(form) {
		console.log(store.getters.loggedUser.token);
		const response = await axios.post(`${api}/users/change_password`, form, {
			headers: {
				'X-API-KEY': `${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
	async updateUserAvatar(form) {
		console.log(store.getters.loggedUser.token);
		const response = await axios.post(`${api}/users/update_image`, form, {
			headers: {
				'X-API-KEY': `${store.getters.loggedUser.token}`,
				'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
			}
		})
		return response?.data
	},
};