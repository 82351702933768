<template>
  <div>
    <v-row :justify="$vuetify.breakpoint.xs ? 'center' : 'start'">
      <div style="width: 300px; text-align: center">
        <v-col>
          <v-row no-gutters align="center">
            <v-col cols="11" style="text-align: center"
              ><div
                class="edit-image"
                v-if="visibleEditBtn && showEditAvatar"
                @click="show = true"
                @mouseleave="visibleEditBtn = false"
              >
                <v-icon large color="white" style="min-height: 185px"
                  >mdi-camera</v-icon
                >
              </div>
              <img
                v-if="loggedUser.profile_image && showEditAvatar"
                height="185px"
                width="185px"
                :src="loggedUser.profile_image"
                alt="avatar"
                style="cursor: pointer; border-radius: 10px"
                @mouseover="visibleEditBtn = true" />
              <img
                v-else
                height="185px"
                width="185px"
                src="@/assets/avatar.png"
                alt="avatar"
                style="cursor: pointer; border-radius: 10px"
                @mouseover="visibleEditBtn = true" />
              <my-upload
                field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="show"
                :width="512"
                :height="512"
                :params="params"
                langType="en"
                noCircle
                :headers="headers"
                img-format="png"
              ></my-upload
            ></v-col>
          </v-row>
          <br />
          <v-row no-gutters justify="center" align="center">
            <v-col cols="11" style="text-align: center;">
              <span class="username">{{
                loggedUser.first_name + " " + loggedUser.last_name
              }}</span>
            </v-col>
            <v-col cols="1" class="pt-2">
              <img
                style="cursor: pointer"
                src="@/assets/editIconProfile.svg"
                @click="editUserInfo = true"
              />
            </v-col>
          </v-row>
          <br />
          <v-row justify="center" no-gutters>
            <span @click="logout" class="log_out">{{
              "profile_button_log_out" | localize
            }}</span>
          </v-row>
        </v-col>
      </div>
      <myActivityTicket />
    </v-row>
    <edit-user-info-modal
      v-if="editUserInfo"
      :showDialog="editUserInfo"
      @close="editUserInfo = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import myActivityTicket from "./myActivityTicket.vue";
import CartService from "../../requests/Cart/CartService";
import editUserInfoModal from "@/components/userProfile/editUserInfo/editUserInfoModal";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import ProfileService from "../../requests/Profile/ProfileService";
export default {
  components: {
    myActivityTicket,
    editUserInfoModal,
    "my-upload": myUpload,
  },
  data: () => ({
    username: "Maria Martinez",
    editUserInfo: false,
    visibleEditBtn: false,
    showEditAvatar: true,
    imgDataUrl: "",
    oldImgUrl: "",
    params: {
      token: "123456798",
      name: "avatar",
    },
    headers: {
      smail: "*_~",
    },
    show: false,
  }),
  methods: {
    ...mapActions(["updateInfo"]),
    ...mapActions(["updateInfoLogged"]),
    async logout() {
      await this.updateInfo({
        locale: this.loggedUser.locale,
      });
      this.$store.commit("clearUserLogged");
      await CartService.clearCart();
      this.$router.push("/");
    },
    async updateUserAvatar() {
      let form = new FormData();
      form.append("image", this.imgDataUrl);
      let response = await ProfileService.updateUserAvatar(form);
      if (response.status == "success") {
        this.updateInfoLogged({
          profile_image: response.data.profile_image,
        });
      }
    },
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;
      console.log("field: " + field);
      this.updateUserAvatar();
    },
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style>
.username {
  font-weight: 600;
  font-size: 24px;
  color: #2f80ed;
}
.log_out {
  font-weight: 600;
  font-size: 16px;
  color: #e62076;
  cursor: pointer;
}
.edit-image {
  height: 185px;
  width: 185px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  align-self: center;
  position: absolute;
  margin-left: 34px;
  cursor: pointer;
  border-radius: 10px;
}
</style>