<template>
  <v-col class="px-0">
    <v-row align="center" :justify="$vuetify.breakpoint.xs ? 'center' : 'start'" class="ml-4">
      <div v-for="citypass in citypass" :key="citypass.id" class="mb-5">
        <tourist-citypass
          v-if="citypass.type == 0"
          :citypass="citypass"
        />
        <local-citypass v-if="citypass.type == 1" :citypass="citypass" />
      </div>
    </v-row>
  </v-col>
</template>

<script>
import touristCitypass from "./touristCitypass.vue";
import localCitypass from "@/components/userProfile/localCitypass";
export default {
  components: {
    touristCitypass,
    localCitypass,
  },
  data: () => ({
    /*citypass: [
      {
        id: 1,
        title: "Discover Guadalajara",
        days: "2 Days",
        type: 0,
        orderNum: "2321321",
        totalCost: "32.5",
        quantity_adult: 2,
        quantity_kid: 1,
        date: "July, 10 2022",
      },
      {
        id: 2,
        title: "Experience Guadalajara",
        days: "Monday-Thursday",
        type: 1,
        orderNum: "2321321",
        totalCost: "32.5",
        quantity_adult: 2,
        quantity_kid: 1,
        date: "July, 10 2022",
      },
    ],*/
  }),
  props: {
    citypass: {
      require: true,
    }
  }
};
</script>

<style>
</style>