<template>
  <v-dialog v-model="visibleDialog" scrollable max-width="600px">
    <v-card>
      <v-card-title>{{'label_edit_profile' | localize}}</v-card-title>
      <v-card-text>
        <v-tabs centered v-model="tab" color="#e62076">
          <v-tab href="#info">Info</v-tab>
          <v-tab href="#password">{{'sign_in_placeholder_password' | localize}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="info"
            >
            <edit-user-info @close="$emit('close')"
          /></v-tab-item>
          <v-tab-item value="password"
            ><edit-user-password @close="$emit('close')"
          /></v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import editUserInfo from "./editUserInfo.vue";
import editUserPassword from "./editUserPassword.vue";
export default {
  data: () => ({
    tab: "",
  }),
  components: {
    editUserInfo,
    editUserPassword,
  },
  props: {
    showDialog: {
      require: true,
    },
  },
  computed: {
    visibleDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style>
</style>