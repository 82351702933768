<template>
  <div class="citypassCard mb-2 mr-5">
    <div class="header">
      <v-row class="px-15 pt-5" align="center" justify="center">
        <v-divider style="border: 1px solid #e62076" />
        <img
          :src="require('@/assets/' + $options.filters.city('main_logo'))"
          class="pass-logo ml-5 mr-5"
          :class="{ 'pass-logo-mzt': mazatlan }"
        />
        <v-divider style="border: 1px solid #e62076" />
      </v-row>
      <v-row justify="center" class="black--text mt-1">
        <div
          style="
            width: 90%;
            height: 60px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <span style="font-weight: 800; font-size: 21px">{{
            locale == "en" ? citypass.title_en : citypass.title_es
          }}</span>
        </div>

        <br />
      </v-row>
      <v-row justify="center" class="mt-4">
        <span style="font-weight: 700; font-size: 14px; color: #6abaa4">
          {{ "city_passes_label_city_pass" | localize }}
        </span>
        <br />
      </v-row>
      <div
        style="background-color: #a459c8; height: 62px"
        :style="citypass.days ? 'margin-top: 15px' : ''"
      >
        <v-row
          justify="center"
          align="center"
          style="font-weight: 800; font-size: 24px"
          class="white--text pt-2"
          v-if="citypass.days"
        >
          <h3 v-if="citypass.days == '1,2,3,4,5'">
            {{ "city_passes_Monday" | localize }} -
            {{ "city_passes_Friday" | localize }}
          </h3>
          <h3 v-if="citypass.days == '1,2,3,4,5,6,7'">
            {{ "city_passes_Monday" | localize }} -
            {{ "city_passes_Sunday" | localize }}
          </h3>
          <h3 v-if="citypass.days == '1,2,3,4'">
            {{ "city_passes_Monday" | localize }} -
            {{ "city_passes_Thursday" | localize }}
          </h3>
          <h3 v-if="citypass.days == '5,6,7'">
            {{ "city_passes_Friday" | localize }} -
            {{ "city_passes_Sunday" | localize }}
          </h3>
          <h3 v-if="citypass.days == '6,7'">
            {{ "city_passes_Saturday" | localize }} -
            {{ "city_passes_Sunday" | localize }}
          </h3>
          <h3 v-if="citypass.days == '28'">
            {{ "city_passes_label_month" | localize }}
          </h3>
          <br />
        </v-row>
        <v-row
          justify="center"
          class="white--text"
          style="font-weight: 800; font-size: 32px; margin-top: 15px"
          v-if="citypass.term"
        >
          <h3>{{ citypass.term }} {{ "city_passes_label_days" | localize }}</h3>
          <br />
        </v-row>
      </div>
    </div>
    <div class="citypass">
      <span>{{ "profile_label_order" | localize }} - {{ citypass.id }}</span>
      <br />
      <span>
        {{ "cart_label_total_cost" | localize }} - MX$&nbsp;{{
          citypass.totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }}
      </span>
      <br />
      <span>
        {{ "attractions_adult" | localize }} -
        {{ parseInt(citypass.quantity_adult) }}
      </span>
      <br />
      <span v-if="citypass.quantity_kid > 0">
        {{ "cart_label_child" | localize }} - {{ citypass.quantity_kid }}
      </span>
      <br v-if="citypass.quantity_kid > 0" />
      <span>{{ "infants_label" | localize }}</span>
      <br />
      <span>
        {{ "profile_label_activation_date" | localize }} -
        <span>{{
          new Date(citypass.started_at).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}</span>
        <!-- timeZone: "Europe/London", -->
        <!-- <span v-else>{{
          new Date(
            citypass.started_at.substr(0, 4),
            citypass.started_at.substr(6, 1) - 1,
            citypass.started_at.substr(8, 2)
          ).toLocaleDateString("es-mx", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        }}</span> -->
      </span>
      <br />
      <v-row justify="center" no-gutters>
        <v-btn
          width="221px"
          height="44px"
          style="
            text-transform: unset !important;
            font-weight: 700;
            font-size: 16px;
          "
          :style="
            citypass.quantity_kid > 0
              ? 'margin-top: 19px;'
              : 'margin-top: 40px;'
          "
          color="#6ABAA4"
          class="white--text"
          @click="
            $router.push(
              '/profile/detail/info/my_citypass/' +
                citypass.citypass_uid +
                '/' +
                citypass.order_uid +
                '/' +
                citypass.parent_uuid
            )
          "
        >
          {{ "profile_button_see_details" | localize }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import cityMixin from "@/mixins/cityMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";

export default {
  mixins: [cityMixin, userSettingsMixin],
  props: {
    citypass: {
      require: true,
    },
  },
};
</script>

<style scoped>
.header {
  height: 208px;
  width: 320px;
  background-color: transparent;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}
.citypass {
  height: 229px;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  padding: 20px;
  padding-top: 31px;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 14px;
}
.citypassCard {
  border: 2px solid #a459c8;
  border-radius: 5%;
  height: 437px;
}
</style>
