<template>
  <v-card>
    <v-card-text>
      <span class="label-profile">{{
        "my_account_current_password" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.password"
        color="#e62076"
        placeholder="Type here.."
        :type="visiblePassword ? 'text' : 'password'"
        :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="visiblePassword = !visiblePassword"
        :error-messages="passwordError | localize"
        @blur="$v.user.password.$touch()"
      >
      </v-text-field>
      <span class="label-profile">{{
        "placeholder_new_password" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.new_password"
        color="#e62076"
        placeholder="Type here.."
        :type="visibleNewPassword ? 'text' : 'password'"
        :append-icon="visibleNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="visibleNewPassword = !visibleNewPassword"
        :error-messages="newPasswordError | localize"
        @blur="$v.user.new_password.$touch()"
      >
      </v-text-field>
      <span class="label-profile">{{
        "reset_password_placeholder_confirm_password" | localize
      }}</span
      ><span class="req"> *</span>
      <v-text-field
        dense
        outlined
        class="rounded-lg"
        v-model="user.confirm_password"
        color="#e62076"
        placeholder="Type here.."
        :type="visibleConfirmPassword ? 'text' : 'password'"
        :append-icon="visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="visibleConfirmPassword = !visibleConfirmPassword"
        :error-messages="confirmPasswordError | localize"
        @blur="$v.user.confirm_password.$touch()"
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-row align="center" justify="end" class="mb-2 mr-1">
        <v-btn text color="#e62076" class="mr-2" @click="$emit('close')">
          {{ "button_cancel" | localize }}
        </v-btn>
        <v-btn color="#e62076" dark @click="updatePassword">
          {{ "button_update" | localize }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import ProfileService from "@/requests/Profile/ProfileService";
export default {
  mixins: [validationMixin],
  data: () => ({
    user: {},
    visiblePassword: false,
    visibleNewPassword: false,
    visibleConfirmPassword: false,
  }),
  validations: {
    user: {
      password: {
        required,
      },
      new_password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  methods: {
    async updatePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("password", this.user.new_password);
        form.append("old_password", this.user.password);
        form.append("type", "web");
        let response = await ProfileService.updatePassword(form);
        if (response.status == "success") {
          this.$emit("close");
        }
      }
    },
  },
  computed: {
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required &&
        errors.push("my_account_current_password_required");
      return errors;
    },
    newPasswordError() {
      const errors = [];
      if (!this.$v.user.new_password.$dirty) {
        return errors;
      }
      if (!this.$v.user.new_password.required) {
        errors.push("my_account_new_password_required");
        return errors;
      }
      if (
        (!this.$v.user.new_password.valid &&
          this.$v.user.new_password.required) ||
        (!this.$v.user.new_password.minLength &&
          this.$v.user.new_password.required)
      ) {
        errors.push("password_hint");
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.user.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.user.confirm_password.required) {
        errors.push("my_account_confirm_password_required");
        return errors;
      }
      if (!this.$v.user.confirm_password.sameAsPassword) {
        errors.push("sign_up_validation_password_doesnt_match");
        return errors;
      }
      return errors;
    },
  },
};
</script>

<style>
.req {
  color: red;
}
</style>
