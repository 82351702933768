<template>
  <v-col
    class="px-5"
    :style="$vuetify.breakpoint.xs ? '' : 'border-left: solid #e62076;'"
  >
    <div :class="$vuetify.breakpoint.xs ? '' : 'ml-5'">
      <v-row no-gutters :justify="$vuetify.breakpoint.xs ? 'center' : ''">
        <v-radio-group row v-model="radioValue" class="mb-5">
          <v-radio value="active">
            <template v-slot:label>
              <span class="black--text textStyle">{{
                "profile_radio_button_active" | localize
              }}</span>
            </template>
          </v-radio>
          <v-radio value="expired">
            <template v-slot:label>
              <span class="black--text textStyle">{{
                "profile_radio_button_expired" | localize
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>
      <div
        v-if="showLoader"
        class="d-flex align-center"
        style="min-height: 50vh"
      >
        <v-progress-circular
          v-if="showLoader"
          class="mx-auto"
          indeterminate
          color="#e62076"
        ></v-progress-circular>
      </div>
      <div
        v-else-if="
          !showLoader && radioValue == 'active' && !citypassList.length
        "
        class="d-flex align-center"
        style="min-height: 50vh; justify-content: center"
      >
        <span>{{ "profile_label_no_active_city_passes" | localize }}</span>
      </div>
      <div
        v-else-if="
          !showLoader && radioValue == 'expired' && !citypassList.length
        "
        class="d-flex align-center"
        style="min-height: 50vh; justify-content: center"
      >
        <span>{{ "profile_label_no_expired_city_passes" | localize }}</span>
      </div>
      <citypass-list
        v-if="radioValue == 'active' && !showLoader"
        :citypass="citypassList"
      />
      <citypass-list
        v-if="radioValue == 'expired' && !showLoader"
        :citypass="citypassList"
      />
    </div>
  </v-col>
</template>

<script>
import citypassList from "./citypassList.vue";
import CartService from "../../requests/Cart/CartService";
//import CitypassService from "../../requests/Citypass/CitypassService";
export default {
  components: {
    citypassList,
  },
  data: () => ({
    radioValue: "active",
    citypassList: [],
    showLoader: true,
  }),
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      let isExpired = 0;
      if (this.radioValue == "active") {
        isExpired = 0;
      } else if (this.radioValue == "expired") {
        isExpired = 1;
      }
      let response = await CartService.getListOrderForUser(isExpired);
      this.citypassList = response.result;
      let order = [];
      let quantity_adult = 0;
      let quantity_kid = 0;
      this.citypassList = this.citypassList.filter(
        (citypass) => citypass.items.length > 0
      );
      for (let index = 0; index < this.citypassList.length; index++) {
        const groupedObjects = this.citypassList[index].items.reduce(
          (acc, obj) => {
            const parent_uuid = obj.parent_uuid;
            if (!acc[parent_uuid]) {
              acc[parent_uuid] = [];
            }
            acc[parent_uuid].push(obj);
            return acc;
          },
          {}
        );
        for (let key in groupedObjects) {
          let response = groupedObjects[key][0].citypass;
          quantity_adult = groupedObjects[key].reduce(
            (prev, current) =>
              current.attraction_uuid ==
                groupedObjects[key][0].attraction_uuid &&
              current.is_adult == "1"
                ? prev + 1
                : prev,
            0
          );
          quantity_kid = groupedObjects[key].reduce(
            (prev, current) =>
              current.attraction_uuid ==
                groupedObjects[key][0].attraction_uuid &&
              current.is_adult == "0"
                ? prev + 1
                : prev,
            0
          );
          // let price = groupedObjects[key].reduce(
          //   (accumulator, currentObject) => {
          //     const priceAsNumber = parseFloat(currentObject.price);
          //     return accumulator + priceAsNumber;
          //   },
          //   0
          // );
          console.log("response", response);
          if (response !== null) {
            order.push({
              order_uid: this.citypassList[index].uuid,
              citypass_uid: response.uuid,
              quantity_adult: quantity_adult,
              quantity_kid: quantity_kid,
              title_en: response.title_en,
              title_es: response.title_es,
              days: response.days,
              term: response.term,
              type: response.type,
              id: this.citypassList[index].id,
              started_at: groupedObjects[key][0].started_at,
              parent_uuid: groupedObjects[key][0].parent_uuid,
              totalCost: this.citypassList[index].price,
            });
            quantity_adult = 0;
            quantity_kid = 0;
          }
        }
      }
      this.citypassList = order;
      this.showLoader = false;
    },
  },
  watch: {
    radioValue: {
      deep: true,
      handler() {
        console.log(this.radioValue);
        this.showLoader = true;
        this.citypassList = [];
        this.getOrders();
      },
    },
  },
};
</script>

<style>
.textStyle {
  font-weight: 700;
  font-size: 16px;
}
</style>